var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AccountContainer',{ref:"accountContainer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var usersStatus = ref.usersStatus;
  var users = ref.users;
  var settingsStatus = ref.settingsStatus;
  var settings = ref.settings;
return [_c('div',{staticClass:"UsersScene"},[_c('div',{staticClass:"UsersScene__header"},[_c('h2',[_vm._v(_vm._s(_vm.$route.meta.title))]),_c('Button',{attrs:{"icon":"far fa-user-plus"},on:{"click":function($event){$event.stopPropagation();return _vm.openCreateUserModal()}}},[_vm._v("Opret bruger")])],1),(usersStatus === _vm.Progress.COMPLETE && users.length === 0)?_c('Alert',{attrs:{"level":_vm.AlertLevel.WARNING}},[_vm._v(" Fandt ingen brugere. ")]):(usersStatus === _vm.Progress.ERROR)?_c('Alert',{attrs:{"level":_vm.AlertLevel.ERROR}},[_vm._v(" Der skete en "),_c('strong',[_vm._v("fejl")]),_vm._v(". Prøv igen senere. ")]):_c('SkeletonTransition',{attrs:{"ready":users.length !== 0},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('Panel',{attrs:{"items":users,"itemKey":"Id"},scopedSlots:_vm._u([{key:"itemContent",fn:function(ref){
  var item = ref.item;
return [_c('UserCard',{staticClass:"UsersScene__userCard",attrs:{"name":item.Name,"email":item.EMail}})]}},{key:"itemControls",fn:function(ref){
  var item = ref.item;
return [_c('Button',{attrs:{"icon":"far fa-user-edit","color":_vm.colors.WHITE,"size":_vm.sizes.SMALL},on:{"click":function($event){$event.stopPropagation();return _vm.openEditUserModal(item)}}},[_vm._v(" Rediger ")]),_c('Button',{attrs:{"icon":"far fa-redo","color":_vm.colors.WHITE,"size":_vm.sizes.SMALL},on:{"click":function($event){$event.stopPropagation();return _vm.openResetPasswordModal(item)}}},[_vm._v(" Nulstil adgangskode ")]),_c('Button',{attrs:{"icon":"far fa-user-minus","color":_vm.colors.RED,"size":_vm.sizes.SMALL},on:{"click":function($event){$event.stopPropagation();return _vm.openDeleteUserModal(item)}}},[_vm._v(" Slet ")])]}}],null,true)})]},proxy:true},{key:"skeleton",fn:function(){return [_c('PanelSkeleton',{attrs:{"refinements":0},scopedSlots:_vm._u([{key:"itemContent",fn:function(){return [_c('UserCardSkeleton')]},proxy:true},{key:"itemControls",fn:function(){return [_c('ButtonSkeleton'),_c('ButtonSkeleton'),_c('ButtonSkeleton')]},proxy:true}],null,true)})]},proxy:true}],null,true)}),_c('Validator',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var valid = ref.valid;
return [_c('ModalDialog',{ref:"createDialog",attrs:{"title":"Opret bruger","working":_vm.createUserStatus === _vm.Progress.WORKING,"confirmLabel":"OPRET BRUGER","confirmIcon":"far fa-user-plus","disableConfirm":settingsStatus !== _vm.Progress.COMPLETE || !valid},on:{"confirm":_vm.createUser}},[_c('SkeletonTransition',{attrs:{"ready":settingsStatus === _vm.Progress.COMPLETE},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('AccountUserSettings',{ref:"createSettings",attrs:{"settings":settings,"mode":_vm.mode}})]},proxy:true},{key:"skeleton",fn:function(){return [_c('AccountUserSettingsSkeleton',{attrs:{"inputs":2}})]},proxy:true}],null,true)})],1)]}}],null,true)}),_c('Validator',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var valid = ref.valid;
return [_c('ModalDialog',{ref:"editDialog",attrs:{"title":"Rediger bruger","working":_vm.editUserStatus === _vm.Progress.WORKING,"confirmLabel":"GEM ÆNDRINGER","confirmIcon":"far fa-user-edit","disableConfirm":settingsStatus !== _vm.Progress.COMPLETE || _vm.userStatus !== _vm.Progress.COMPLETE || !valid},on:{"confirm":_vm.updateUser}},[_c('SkeletonTransition',{attrs:{"ready":settingsStatus === _vm.Progress.COMPLETE && _vm.userStatus === _vm.Progress.COMPLETE},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('AccountUserSettings',{ref:"editSettings",attrs:{"settings":settings,"mode":_vm.mode,"user":_vm.user}})]},proxy:true},{key:"skeleton",fn:function(){return [_c('AccountUserSettingsSkeleton')]},proxy:true}],null,true)})],1)]}}],null,true)}),_c('ModalDialog',{ref:"resetDialog",attrs:{"title":"Nulstil adgangskode","working":_vm.resetPasswordStatus === _vm.Progress.WORKING,"confirmLabel":"NULSTIL ADGANGSKODE","confirmColor":_vm.colors.RED,"confirmIcon":"far fa-redo"},on:{"confirm":function($event){return _vm.resetPassword(_vm.user)}}},[_vm._v(" "+_vm._s(("Er du sikker på at du vil nustille adgangskode for " + (_vm.user ? _vm.user.Name : '') + "?"))+" ")]),_c('ModalDialog',{ref:"deleteDialog",attrs:{"title":("Slet " + (_vm.user ? _vm.user.Name : '')),"working":_vm.deleteUserStatus === _vm.Progress.WORKING,"confirmLabel":"SLET BRUGER","confirmColor":_vm.colors.RED,"confirmIcon":"far fa-user-minus"},on:{"confirm":function($event){return _vm.deleteUser(_vm.user.Id)}}},[_vm._v(" "+_vm._s(("Er du sikker på at du vil slette " + (_vm.user ? _vm.user.Name : '') + "?"))+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }