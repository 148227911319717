<template>
  <AccountContainer
    ref="accountContainer"
    v-slot="{
      usersStatus,
      users,
      settingsStatus,
      settings,
    }"
  >
    <div class="UsersScene">
      <div class="UsersScene__header">
        <h2>{{ $route.meta.title }}</h2>
        <Button icon="far fa-user-plus" @click.stop="openCreateUserModal()">Opret bruger</Button>
      </div>
      <Alert
        v-if="usersStatus === Progress.COMPLETE && users.length === 0"
        :level="AlertLevel.WARNING"
      >
        Fandt ingen brugere.
      </Alert>
      <Alert v-else-if="usersStatus === Progress.ERROR" :level="AlertLevel.ERROR">
        Der skete en <strong>fejl</strong>. Prøv igen senere.
      </Alert>
      <SkeletonTransition v-else :ready="users.length !== 0">
        <template #default>
          <Panel :items="users" itemKey="Id">
            <template #itemContent="{ item }">
              <UserCard :name="item.Name" :email="item.EMail" class="UsersScene__userCard" />
            </template>
            <template #itemControls="{ item }">
              <Button
                icon="far fa-user-edit"
                :color="colors.WHITE"
                :size="sizes.SMALL"
                @click.stop="openEditUserModal(item)"
              >
                Rediger
              </Button>
              <Button
                icon="far fa-redo"
                :color="colors.WHITE"
                :size="sizes.SMALL"
                @click.stop="openResetPasswordModal(item)"
              >
                Nulstil adgangskode
              </Button>
              <Button
                icon="far fa-user-minus"
                :color="colors.RED"
                :size="sizes.SMALL"
                @click.stop="openDeleteUserModal(item)"
              >
                Slet
              </Button>
            </template>
          </Panel>
        </template>
        <template #skeleton>
          <PanelSkeleton :refinements="0">
            <template #itemContent>
              <UserCardSkeleton />
            </template>
            <template #itemControls>
              <ButtonSkeleton />
              <ButtonSkeleton />
              <ButtonSkeleton />
            </template>
          </PanelSkeleton>
        </template>
      </SkeletonTransition>
      <Validator v-slot="{ valid }">
        <ModalDialog
          ref="createDialog"
          title="Opret bruger"
          :working="createUserStatus === Progress.WORKING"
          confirmLabel="OPRET BRUGER"
          confirmIcon="far fa-user-plus"
          :disableConfirm="settingsStatus !== Progress.COMPLETE || !valid"
          @confirm="createUser"
        >
          <SkeletonTransition :ready="settingsStatus === Progress.COMPLETE">
            <template #default>
              <AccountUserSettings ref="createSettings" :settings="settings" :mode="mode" />
            </template>
            <template #skeleton>
              <AccountUserSettingsSkeleton :inputs="2" />
            </template>
          </SkeletonTransition>
        </ModalDialog>
      </Validator>
      <Validator v-slot="{ valid }">
        <ModalDialog
          ref="editDialog"
          title="Rediger bruger"
          :working="editUserStatus === Progress.WORKING"
          confirmLabel="GEM ÆNDRINGER"
          confirmIcon="far fa-user-edit"
          :disableConfirm="
            settingsStatus !== Progress.COMPLETE || userStatus !== Progress.COMPLETE || !valid
          "
          @confirm="updateUser"
        >
          <SkeletonTransition
            :ready="settingsStatus === Progress.COMPLETE && userStatus === Progress.COMPLETE"
          >
            <template #default>
              <AccountUserSettings
                ref="editSettings"
                :settings="settings"
                :mode="mode"
                :user="user"
              />
            </template>
            <template #skeleton>
              <AccountUserSettingsSkeleton />
            </template>
          </SkeletonTransition>
        </ModalDialog>
      </Validator>
      <ModalDialog
        ref="resetDialog"
        title="Nulstil adgangskode"
        :working="resetPasswordStatus === Progress.WORKING"
        confirmLabel="NULSTIL ADGANGSKODE"
        :confirmColor="colors.RED"
        confirmIcon="far fa-redo"
        @confirm="resetPassword(user)"
      >
        {{ `Er du sikker på at du vil nustille adgangskode for ${user ? user.Name : ''}?` }}
      </ModalDialog>
      <ModalDialog
        ref="deleteDialog"
        :title="`Slet ${user ? user.Name : ''}`"
        :working="deleteUserStatus === Progress.WORKING"
        confirmLabel="SLET BRUGER"
        :confirmColor="colors.RED"
        confirmIcon="far fa-user-minus"
        @confirm="deleteUser(user.Id)"
      >
        {{ `Er du sikker på at du vil slette ${user ? user.Name : ''}?` }}
      </ModalDialog>
    </div>
  </AccountContainer>
</template>

<script>
import AccountContainer from '@containers/AccountContainer';
import Validator from '@containers/Validator';
import { Button, sizes, colors } from '@components/Buttons';
import { ModalDialog } from '@components/Modal';
import {
  SkeletonTransition,
  AccountUserSettingsSkeleton,
  ButtonSkeleton,
  PanelSkeleton,
  UserCardSkeleton,
} from '@components/Skeleton';
import Panel from '@scenes/SelfserviceScene/components/Panel';
import UserCard from '@scenes/SelfserviceScene/components/UserCard';
import AccountUserSettings, { Modes } from './components/AccountUserSettings';
import Progress from '@types/Progress';
import Alert, { AlertLevel } from '@components/Alert';

export default {
  name: 'UsersScene',

  components: {
    AccountContainer,
    Validator,
    Button,
    ModalDialog,
    SkeletonTransition,
    AccountUserSettingsSkeleton,
    ButtonSkeleton,
    PanelSkeleton,
    UserCardSkeleton,
    Panel,
    UserCard,
    AccountUserSettings,
    Alert,
  },

  enums: {
    sizes,
    colors,
    Progress,
    AlertLevel,
  },

  data() {
    return {
      mode: '',
      user: null,
      userStatus: '',
      createUserStatus: '',
      editUserStatus: '',
      resetPasswordStatus: '',
      deleteUserStatus: '',
    };
  },

  mounted() {
    this.$refs.accountContainer.fetchUsers();
  },

  methods: {
    openCreateUserModal() {
      this.$refs.accountContainer.fetchUserSettings();
      this.mode = Modes.CREATE;
      this.$refs.createDialog.open();
    },
    openEditUserModal(user) {
      this.userStatus = Progress.WORKING;
      this.$refs.accountContainer.fetchUserSettings();
      this.$refs.accountContainer
        .fetchUser(user.Id)
        .then(data => {
          this.user = data;
          this.userStatus = Progress.COMPLETE;
        })
        .catch(error => {
          this.user = null;
          this.userStatus = Progress.ERROR;
        });
      this.mode = Modes.EDIT;
      this.$refs.editDialog.open();
    },
    openDeleteUserModal(user) {
      this.userStatus = '';
      this.user = user;
      this.$refs.deleteDialog.open();
    },
    openResetPasswordModal(user) {
      this.userStatus = '';
      this.user = user;
      this.$refs.resetDialog.open();
    },
    createUser() {
      const container = this.$refs.accountContainer;
      if (container.accountStatus !== Progress.COMPLETE) return;
      const newUser = this.$refs.createSettings.getUser();
      this.createUserStatus = Progress.WORKING;
      container
        .createUser({
          UserName: newUser.Name,
          EMail: newUser.EMail,
          AccountNumber: container.account.account_number,
          Settings: newUser.Settings,
        })
        .then(data => {
          this.$refs.createDialog.close();
          this.createUserStatus = Progress.COMPLETE;
        })
        .catch(error => {
          this.$refs.createDialog.close();
          this.createUserStatus = Progress.ERROR;
        });
    },
    updateUser() {
      const newUser = this.$refs.editSettings.getUser();
      const user = {};
      if (this.user.EMail !== newUser.EMail) {
        user.Email = newUser.EMail;
      }
      if (!this.isSameSettings([...this.user.Settings], newUser.Settings)) {
        user.Settings = newUser.Settings;
      }
      this.editUserStatus = Progress.WORKING;
      this.$refs.accountContainer
        .updateUser(this.user.Id, user)
        .then(data => {
          this.$refs.editDialog.close();
          this.editUserStatus = Progress.COMPLETE;
        })
        .catch(error => {
          this.$refs.editDialog.close();
          this.editUserStatus = Progress.ERROR;
        });
    },
    isSameSettings(a, b) {
      if (a.length !== b.length) return false;
      a.sort(this.compareSettings);
      b.sort(this.compareSettings);
      for (let i = 0; i < a.length; i++) {
        if (a[i].SettingId !== b[i].SettingId) return false;
      }
      return true;
    },
    compareSettings(a, b) {
      return a.SettingId - b.SettingId;
    },
    resetPassword(user) {
      const container = this.$refs.accountContainer;
      if (container.accountStatus !== Progress.COMPLETE) return;
      this.resetPasswordStatus = Progress.WORKING;
      container
        .resetUserPassword(user.EMail, container.account.account_number, user.Name)
        .then(data => {
          this.$refs.resetDialog.close();
          this.resetPasswordStatus = Progress.COMPLETE;
        })
        .catch(error => {
          this.$refs.resetDialog.close();
          this.resetPasswordStatus = Progress.ERROR;
        });
    },
    deleteUser(id) {
      this.deleteUserStatus = Progress.WORKING;
      this.$refs.accountContainer
        .deleteUser(id)
        .then(data => {
          this.$refs.deleteDialog.close();
          this.deleteUserStatus = Progress.COMPLETE;
        })
        .catch(error => {
          this.$refs.deleteDialog.close();
          this.deleteUserStatus = Progress.ERROR;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.UsersScene {
  .UsersScene__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }

  .UsersScene__userCard {
    min-width: 220px;
  }
}
</style>
