<template>
  <div class="AccountUserSettings">
    <Input
      id="AccountUserSettings__inputUserName"
      label="Brugernavn"
      required
      :disabled="mode === Modes.EDIT"
      class="AccountUserSettings__input"
      v-model="newName"
    />
    <Input
      v-if="mode === Modes.EDIT"
      id="AccountUserSettings__inputUserExtKey"
      :value="user.Ext_ID"
      label="Bruger nøgle"
      disabled
      class="AccountUserSettings__input"
    />
    <Input
      id="AccountUserSettings__inputUserEmail"
      type="email"
      label="Email"
      required
      class="AccountUserSettings__input"
      v-model="newEmail"
    />
    <div v-for="setting in settings" :key="setting.SettingId" class="AccountUserSettings__setting">
      <input
        :id="'AccountUserSettings__setting' + setting.SettingId"
        type="checkbox"
        :value="setting.SettingId"
        v-model="newSettingKeys"
      />
      <label :for="'AccountUserSettings__setting' + setting.SettingId">{{ setting.Name }}</label>
    </div>
  </div>
</template>

<script>
import Input from '@scenes/SelfserviceScene/components/Input';

export const Modes = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

export default {
  name: 'AccountUserSettings',

  components: {
    Input,
  },

  enums: {
    Modes,
  },

  props: {
    settings: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      default: Modes.CREATE,
    },
    user: {
      type: Object,
      default() {
        return { Name: '', EMail: '', Ext_ID: '', Settings: [] };
      },
    },
  },

  data() {
    return {
      newName: this.user.Name,
      newEmail: this.user.EMail,
      newSettingKeys: [],
    };
  },

  created() {
    this.user.Settings.forEach(setting => {
      this.newSettingKeys.push(setting.SettingId);
    });
  },

  methods: {
    getUser() {
      return {
        Name: this.newName,
        EMail: this.newEmail,
        Settings: this.newSettingKeys.map(key => ({ SettingId: key })),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.AccountUserSettings {
  .AccountUserSettings__input {
    margin-bottom: 15px;
  }

  .AccountUserSettings__setting {
    display: flex;
    gap: 10px;
    margin-top: 4px;
  }
}
</style>
